import type { T3Link } from '@t3headless/nuxt-typo3'

/**
 * Extracts a valid link from the input. A valid link is either a direct string that matches
 * URL patterns (such as http, https, mailto, or tel links) or an object containing a 'url' or
 * 'href' property with a string value that matches those patterns.
 *
 * @param {string | T3Link | null | undefined} link - The link to be validated. Can be a string, a T3Link object, or null/undefined.
 * @returns {string | null} The valid link as a string if found, null otherwise.
 */
export const getValidLink = (
  link: string | T3Link | null | undefined
): string | null => {
  // Define the URL pattern for matching
  const isUrl = (link: string) =>
    /^((http(s)?|ftp):\/\/|\/)|(mailto|tel):|#\w+/.test(link)

  // Direct string URL check
  if (typeof link === 'string') {
    return isUrl(link) ? link : null
  }

  // Object with URL properties check
  if (typeof link === 'object' && link !== null) {
    const objectValues = Object.values(link)
    return (
      objectValues.find((value) => typeof value === 'string' && isUrl(value)) ||
      null
    )
  }

  // Default return null for non-matching types or values
  return null
}

/**
 * Determines whether the provided input is a valid link by utilizing getValidLink to extract
 * the link and then converting the result to a boolean.
 *
 * @param {string | T3Link | null | undefined} link - The link to be validated. Can be a string, a T3Link object, or null/undefined.
 * @returns {boolean} True if the link is valid, false otherwise.
 */
export const isLinkValid = (
  link: string | T3Link | null | undefined
): boolean => {
  return Boolean(getValidLink(link))
}

/**
 * Determines whether a given link is an internal page relative to a specified base URL.
 * The function checks if the link starts with the base URL, indicating it's internal.
 * Additionally, it uses a regular expression to check for links that do not start with
 * multiple forward slashes, a protocol scheme (like http, https), mailto:, or tel:,
 * suggesting they are relative links and thus internal by default.
 *
 * @param {string | null | undefined} link - The link to be checked for being an internal page. Can be null or undefined.
 * @param {string} baseUrl - The base URL to compare against to determine if the link is internal.
 * @returns {boolean} True if the link is considered internal to the baseUrl, false otherwise.
 */
export const isInternalPage = (
  link: string | null | undefined,
  baseUrl: string
): boolean => {
  if (!link) return false

  return (
    link.startsWith(baseUrl) ||
    /^(?!\/{2,}|[a-zA-Z]+:|mailto:|tel:).+/.test(link)
  )
}

/**
 * Checks if a given link is included in a predefined list of company-related links.
 * This function normalizes the input link and compares it against the normalized versions
 * of links in a specified array, to determine if there is a match.
 *
 * @param {string | null | undefined} link - The link to check if it belongs to the company's list of links. Can be null or undefined.
 * @returns {boolean} True if the link matches any link in the company's list, false otherwise.
 */
export const isCompanyPage = (link: string | null | undefined): boolean => {
  if (!link) return false

  // Skipping the check when it is an internal link.
  if (link.startsWith('/')) return true

  // Predefined array of company-related links.
  const linksArray = ['macopedia.com', 't3headless.io', 't3pwa.com']

  let normalizedLink: string

  // Attempt to normalize the input link
  try {
    normalizedLink = new URL(link).hostname
  } catch (error) {
    console.error(`Invalid URL: ${link}`, error)
    // Return false or handle it as per requirement if the URL is not valid
    return false
  }

  if (/(mailto|tel)/.test(normalizedLink)) return false

  // Check if the normalized link is in the array of company links
  return linksArray.some((arrayLink) => normalizedLink.includes(arrayLink))
}

/**
 * Checks if the value of the target attribute is valid. A target is considered valid if it is
 * one of the following: '_blank', '_self', '_parent', '_top'. This function ensures that links
 * open in a manner that is consistent with web standards and security best practices.
 *
 * @param {string | null | undefined} target - The value of the target attribute to be checked. Can be null or undefined.
 * @returns {boolean} True if the target attribute's value is valid, false otherwise.
 */
export const isTargetAttributeValid = (
  target: string | null | undefined
): boolean => {
  return !(target && !['_blank', '_self', '_parent', '_top'].includes(target))
}
